import createLoungeService from './loungeService';
import { trackInfo } from '../service/musicService';

function votingService(loungeID) {
  const loungeService = createLoungeService(loungeID);

  return {
    async vote(song) {
      try {
        return await loungeService.request('post', '/voting/vote', { songID: song });
      } catch (error) {
        console.error("Error while casting vote:", error);
        throw error;
      }
    },

    async rankingFreeBoost(songID) {
      try {
        return await loungeService.request('post', '/ranking/boost/free', { songID });
      } catch (error) {
        console.error("Error while applying boost:", error);
        throw error;
      }
    },

    async votingPayment(object) {
      try {
        return await loungeService.request('post', '/voting/boost/payment', object);
      } catch (error) {
        console.error("Error while casting vote:", error);
        throw error;
      }
    },

    async rankingPayment(object) {
      try {
        return await loungeService.request('post', '/ranking/payment', object);
      } catch (error) {
        console.error("Error while casting vote:", error);
        throw error;
      }
    },

    async applyPromocode(songId, promocode) {
      try {
      
        const songInfo = await trackInfo(songId);

        const data = {
          songId: songId,
          songName: songInfo.songName,
          artist: songInfo.artist,
          image: songInfo.image,
          code: promocode
        }
        return await loungeService.request('post', '/ranking/code', data);
      } catch (error) {
        console.error("Error while applying promo code:", error);
        throw error;
      }
    }
  };
}
export default votingService;
