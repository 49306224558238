<template>
  <NavBar />
  <SimpleSpinner v-if="isInitialLoading"/>
  <div class="relative" v-else>
    <div class="flex flex-col items-start gap-3 px-8 pt-4 pb-10 w-full">
      <p v-if="timerIsNull && !isRankingFinished" class="font-semibold">¡Lanza la cuenta atrás para reproducir la canción que vaya primera!</p>
      <p v-if="!timerIsNull" class="font-semibold">Deberás reproducir la canción que vaya primera cuando se acabe el contador y te avise.</p>
      <p v-if="isRankingFinished" class="font-semibold">¡Debería estar sonando la canción que está redondeada en rojo!</p>
      <p v-if="noRanking" class="font-semibold">Ahora mismo no hay ningún ranking activo</p>
      <div class="flex items-center justify-between w-full">
        <Timer v-if="detailsLoaded && !timerIsNull" :initialTime="timeLeft" />
        <Button v-if="timerIsNull && !isRankingFinished"
          @click="handleOpenLaunchModal" 
          class="flex items-center bg-tp-yellow gap-1 py-2 px-4 rounded-full cursor-pointer text-black min-w-24"
          text="Lanzar"
          :isLoading="isRankingLaunchLoading" :disabled="isGeneralVotingActive">
          <img :src="clock" alt="" class="w-4 h-4">
        </Button>
      </div>
      <DJRankingSongCard v-if="isRankingFinished" 
        :rankingSong="songToPlay"
        :index="1" 
        :isGeneralVoting="true"  
        :isShouldPlay="true"/>
      <div class="flex flex-col gap-3 w-full">
        <DJRankingSongCard v-for="(rankingSong, index) in rankingSongs" 
        :key="index" 
        :rankingSong="rankingSong" 
        :index="index+1" 
        :isShouldPlay="false" 
        @deleteSong="handleOpenRejectSongModal(rankingSong.songId)"/>
      </div>
      <DJShouldPlaySongModal v-if = "rankingFinishedModal" :songToShow="songToPlay" @onCloseModal="handleCloseModal"/>
    </div>
    <Transition name="modal-fade">
      <DJConfirmLaunchModal v-if="confirmLaunchModalVisible" @onLaunch="startCountdown" @onCloseModal="handleCloseLaunchModal"/>
    </Transition>
    <Transition name="modal-fade">
      <DJConfirmRejectSongModal v-if="rejectSongModalVisible" :songId="songToReject" @onRejectSong="handleDeleteSong" @onCloseModal="handleCloseRejectModal"/>
    </Transition>
    <div class="flex justify-center">
      <button
        v-if="timerIsNull" 
        class="bg-tp-yellow p-3 rounded-xl mb-4 mx-auto" 
        @click="openCloseSessionModal">
        <p class="text-tp-black font-bold">Cerrar sesión</p>
      </button>
    </div>
    <Transition name="modal-fade">
      <DJCloseSessionModal v-if="closeSessionModal"  @onCloseSession="handleCloseSession" @onCloseModal="handleCloseCloseSessionModal"/>
    </Transition>
  </div>


</template>

<script>
import NavBar from '@/components/Common/NavBar.vue';
import DJRankingSongCard from '@/components/DJ/DJRankingSongCard.vue';
import DJCloseSessionModal from '@/components/DJ/DJCloseSessionModal.vue';
import clock from '@/assets/images/black-clock.png';
import { useRouter, useRoute } from 'vue-router'
import { ref, onMounted, onBeforeUnmount} from 'vue';
import createLoungeService from '@/service/loungeService';
import { rejectSong, closeSession } from '../../service/djService';
import Timer from '@/components/Common/Timer.vue';
import DJShouldPlaySongModal from '../../components/DJ/DJShouldPlaySongModal.vue';
import DJConfirmRejectSongModal from '../../components/DJ/DJConfirmRejectSongModal.vue';
import { rankingCountdown } from '../../service/djService';
import SimpleSpinner from '@/components/Common/SimpleSpinner.vue';
import Button from '@/components/Common/Button.vue';
import DJConfirmLaunchModal from '../../components/DJ/DJConfirmLaunchModal.vue';

export default {
  name: 'DJRanking',
  components: {
    NavBar,
    DJRankingSongCard,
    Timer,
    DJShouldPlaySongModal,
    DJConfirmRejectSongModal,
    SimpleSpinner,
    Button,
    DJCloseSessionModal,
    DJConfirmLaunchModal
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const loungeId = route.params.id;
    const loungeDetails = ref(null);
    const detailsLoaded = ref (false);
    const rankingSongs = ref(null);
    const timeLeft = ref (null);
    const timerIsNull = ref(null);
    const rankingFinishedModal = ref(false);
    const isRankingFinished = ref(false);
    const noRanking = ref (false);
    const pollingInterval = ref(null);
    const rejectSongModalVisible = ref(false);
    const songToReject = ref(null);
    const songToPlay = ref(null);
    const isInitialLoading = ref(true);
    const isRankingLaunchLoading = ref(false);
    const closeSessionModal = ref (false);
    const confirmLaunchModalVisible = ref (false);
    const isGeneralVotingActive = ref(false);

    const { fetchLoungeDetails } = createLoungeService();

    const fetchDetails = async () => {
        if (!loungeId) return;
        const details = await fetchLoungeDetails(loungeId);
        if (details && details.public) {
          loungeDetails.value = details;
        }
        checkState();
        if (loungeDetails.value && loungeDetails.value.public && loungeDetails.value.public.ranking) {
            const songsData = Object.values(loungeDetails.value.public.ranking.songs); 
            updateRanking(songsData);
            if (loungeDetails.value.public.ranking.timeLeft === null){
              timerIsNull.value = true;
            }
            else{
              timerIsNull.value = false;
              if(isRankingFinished.value === true){
                timeLeft.value = 180 + loungeDetails.value.public.ranking.timeLeft;

              }
              else{
                timeLeft.value = loungeDetails.value.public.ranking.timeLeft;
              }
              
            }
            detailsLoaded.value = true;
        }
    };

    const updateRanking = async (songsData) => {
      rankingSongs.value = songsData.map(song => {
          return {
            ...song.songInfo,
            votes: song.votos,
            revenue: song.revenue
          };
        })
    };

    const checkState = async () => {
      if (loungeDetails.value.public && loungeDetails.value.public.status){
        if (loungeDetails.value.public.status === "RANKING" || loungeDetails.value.public.status === "FINISHED_GENERAL_VOTE"){
          isRankingFinished.value = false;
        } else if (loungeDetails.value.public.status === "FINISHED_RANKING"){
          if (isRankingFinished.value !== true){
            const songDetails = loungeDetails.value.public.lastRankingWinner;
            songToPlay.value =  songDetails;
            rankingFinishedModal.value =true;
          }
          isRankingFinished.value=true;
        } else if (loungeDetails.value.public.status === "GENERAL_VOTE"){
          isGeneralVotingActive.value = true;
          router.push(`/dj/active-session/${loungeId}/active-voting`)
        } else{
          noRanking.value=true;
          router.push('/dj/home')
        }
      }

    }

    const openCloseSessionModal = () => {
      closeSessionModal.value = true;
    };

    const handleCloseCloseSessionModal = () => {
      closeSessionModal.value = false;
    };

    const handleCloseSession = () => {
      closeSession(loungeId);
      closeSessionModal.value = false;
    };

    const handleCloseModal = () => {
      rankingFinishedModal.value = false;
    }
    
    const startCountdown = async () => {
      try{
        isRankingLaunchLoading.value = true;
        confirmLaunchModalVisible.value = false;
        await rankingCountdown(loungeId);       
        await new Promise(resolve => setTimeout(resolve, 3000));
        isRankingLaunchLoading.value = false;
      }
      catch {
        isRankingLaunchLoading.value = false;
        console.log('error')
      }
    }

    onMounted(async () => {
        await fetchDetails(); 
        pollingInterval.value = setInterval(fetchDetails, 3000);
        isInitialLoading.value = false;
    });

    onBeforeUnmount(() => {
      clearInterval(pollingInterval.value);
      pollingInterval.value = null; 
    });

    const handleDeleteSong = () => {
      rejectSong (songToReject.value, loungeId)
      rejectSongModalVisible.value = false;
    }

    const handleOpenRejectSongModal = (song) => {
      songToReject.value = song;
      rejectSongModalVisible.value = true;
    }

    const handleOpenLaunchModal = () => {
      confirmLaunchModalVisible.value = true;
    }

    const handleCloseLaunchModal = () => {
      confirmLaunchModalVisible.value = false;
    }

    const handleCloseRejectModal = () => {
      rejectSongModalVisible.value = false;
    }

    return {
      clock,
      rankingSongs,
      handleDeleteSong,
      detailsLoaded,
      timerIsNull,
      timeLeft,
      rankingFinishedModal,
      handleCloseModal,
      isRankingFinished,
      noRanking,
      rejectSongModalVisible,
      songToReject,
      handleOpenRejectSongModal,
      handleCloseRejectModal,
      startCountdown,
      songToPlay,
      isInitialLoading,
      isRankingLaunchLoading,
      openCloseSessionModal,
      handleCloseCloseSessionModal,
      handleCloseSession,
      closeSessionModal,
      confirmLaunchModalVisible,
      handleOpenLaunchModal,
      handleCloseLaunchModal
    }
  }
}
</script>