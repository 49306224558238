// getSuperCookie.js

async function getSuperCookie() {
    const components = [];

    // Información del navegador
    components.push(`User Agent: ${navigator.userAgent}`);
    components.push(`Platform: ${navigator.platform}`);
    components.push(`Language: ${navigator.language || 'unknown'}`);

    // Propiedades de pantalla normalizadas
    const normalizedWidth = Math.round(screen.width / 100) * 100;
    const normalizedHeight = Math.round(screen.height / 100) * 100;
    const roundedDPR = Math.round(window.devicePixelRatio || 1);
    components.push(`Screen: ${normalizedWidth}x${normalizedHeight} (${screen.colorDepth} bit)`);
    components.push(`Device Pixel Ratio: ${roundedDPR}`);

    // Offset de la zona horaria
    const timeZoneOffset = new Date().getTimezoneOffset();
    components.push(`Timezone Offset: ${timeZoneOffset}`);

    // Información de hardware
    components.push(`Hardware Concurrency: ${navigator.hardwareConcurrency}`);
    components.push(`Max Touch Points: ${navigator.maxTouchPoints || 0}`);

    // Obtener la IP usando un API externa
    //const ip = await getUserIP();
    //components.push(`IP Address: ${ip}`);

    // Fingerprint de Canvas
    components.push(`Canvas: ${getCanvasFingerprint()}`);

    // Fingerprint de WebGL
    components.push(`WebGL: ${getWebGLFingerprint()}`);

    // Fingerprint de AudioContext
    components.push(`Audio: ${getStableAudioFingerprint()}`);

    // Combinación y hash
    const fingerprint = components.join(' | ');
    return hashString(fingerprint);
}

// Función para obtener la IP

// Canvas Fingerprinting
function getCanvasFingerprint() {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.textBaseline = 'top';
    ctx.font = '16px Arial';
    ctx.fillStyle = 'black';
    ctx.fillText('Canvas Fingerprinting', 10, 10);
    ctx.strokeStyle = 'red';
    ctx.strokeRect(5, 5, 150, 20);
    return canvas.toDataURL();
}

// WebGL Fingerprinting
function getWebGLFingerprint() {
    try {
        const canvas = document.createElement('canvas');
        const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
        const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
        const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
        const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
        return `Vendor: ${vendor}, Renderer: ${renderer}`;
    } catch (e) {
        return 'WebGL not supported';
    }
}

// AudioContext Fingerprinting
function getStableAudioFingerprint() {
    try {
        const context = new (window.AudioContext || window.webkitAudioContext)();
        const oscillator = context.createOscillator();
        oscillator.type = 'triangle';
        oscillator.frequency.setValueAtTime(10000, context.currentTime);

        const analyser = context.createAnalyser();
        const gain = context.createGain();
        const scriptProcessor = context.createScriptProcessor(4096, 1, 1);

        oscillator.connect(gain);
        gain.connect(analyser);
        analyser.connect(scriptProcessor);
        scriptProcessor.connect(context.destination);

        const fingerprintData = new Float32Array(analyser.frequencyBinCount);
        analyser.getFloatFrequencyData(fingerprintData);

        oscillator.start(0);
        oscillator.stop(context.currentTime + 0.01);

        const normalizedData = fingerprintData.slice(0, 10).map(Math.round).toString();
        return normalizedData;
    } catch (e) {
        return 'AudioContext not supported';
    }
}

// Función de hash para generar el supercookie
function hashString(str) {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0;
    }
    return hash;
}

export default getSuperCookie;
